import React from 'react';
import '../style/testLogo.scss'

const TestLogo = () => {
    return (
        <div className='test-wrap'>
            <div className='logo-wrap'>
                    
            </div>
        </div>
    );
};

export default TestLogo;